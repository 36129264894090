import React from 'react'

const Terms = () => {
    return (
        <div className="container-sm">
            <h1 className="text-2xl  text-brandPrimary text-center font-semibold my-4">TERMS AND CONDITIONS</h1>
            <ol className='termsAndCondition' >
                <li>All students admitted in Annai are abided by rules and regulations of the Institution.</li>

                <li>Any violation of rules and regulations will attract corrective action/Punishment.
                </li>
                <li>Working Hours is 9.30 AM to 4.00PM
                </li>
                <li>Students should present 15 minutes earlier to the working time.
                </li>
                <li>Under any circumstances students won't be allowed to leave the premises earlier to

                    working time.
                </li>
                <li>Students should not seek any permission to leave the Institution before the stipulated working Hours.
                </li>
                <li>All Students should attend the classes in prescribed uniform only.
                </li>
                <li>Uniform should be clean and neat.
                </li>
                <li>Students should not avail any unnecessary leaves. Students taking leaves should take appropriate permission earlier to the leave.
                </li>
                <li>If leave is under any emergency situation, students should submit their leave letter in the next working day.
                </li>
                <li>Students taken leave for more than 3 days will allowed to institution only after meeting their parents.
                </li>
                <li>If the Students need to leave the institution in working hours, they will be allowed only if the parents come in person and pick their wards. 7
                </li>
                <li>Students securing above 80% attendance only will be allowed to attend the final exams.
                </li>
                <li>Grace Attendance percentage of 10% will be allowed with Attendance Fine of Rs. 150/-per percentage.
                </li>
                <li>Students having less than 70% attendance will not be allowed to attend the final exams.
                </li>
                <li>No visitors are allowed to see students during working hours.
                </li>
                <li>Any emergencies should be communicated to office only. Office contact No. Kanchipuram :044-27233344, 9944930018, Vellore: 04166-2220528, 9677760143.
                </li>
                <li>Usage of phone by students inside the premises is strictly prohibited. So Students should not bring their mobile phones to the Institution.
                </li>
                <li>If students bring their mobile phones for their personal requirements, they should submit their mobile phones in the reception and to get phone card. Mobile phones will be returned back to students only after working hours after receipt of phone cards. If Phone cards are lost, mobile phones will be returned only to their parents.
                </li>
                <li>Mobile accessories like Headphone, SIM, Data card, Memory Card will not be received with mobile phones. If students leave those accessories with mobile phone should be on their own discretion. Management will not hold any responsible for those accessories.
                </li>
                <li>If any mobile phones seized from students inside the institution during working hours will only be returned to their parents.
                </li>
                <li>Students Tablet issued to students is only for education purpose. If noticed on other usage tablet will be seized and only will be returned at the end of the academic year.
                </li>
                <li>All Students should strictly pay their monthly fee due amount before 10th of every month.
                </li>
                <li> Students paying fees late should pay a fine of Rs.5/- per day.
                </li>
                <li>Students admitted for any course should complete the course period specified. If shouldn't complete the course due to any reasons should follow the discontinuation procedure stated below to discontinue the course

                    Discontinue before 3 months: 50% of One Year Fee to be paid

                    Discontinue after 3 months: 100% of One Year Fee to be paid

                    Discontinue after 1 Year: 50% of Second Year Fee to be paid
                </li>
                <li>All Students should submit their original TC and Mark sheet at the time of admission. Original certificates will not be given in between for any reasons. Students are requested to take enough photo copies.
                </li>
                <li>Students will be given certificate deposit slip while admission on receipt of Certificates will only be returned after completion of their courses to students after receiving the certificate deposit slip.

                    Fee once paid will not be refunded under any circumstances.
                </li>
                <li>Ragging is prohibited by law, hence anybody indulging in such practices are punishable by law.
                </li>
                <li>Admitting students to the course is the sole discretion of the management.
                </li>
                <li>Students falling ill during working hours will be given with first aid by the management. If any further treatment required to the students like hospitalization will be done by management only after getting permission from their parents. Expenses occurred during such cases to be borne by the students.

                    Students32.  fallen ill during working hours will be permitted to classes only after receipt of proper fitness certificate.
                </li>
                <li>Students should not participate in any political activities.
                </li>
                <li>Students should not involve in making any groups inside the institution premises. Students should not involve in any activities which is against the management of Annai. If found will be terminated from course immediately without any notice.
                </li>
                <li>should not involve in any activities with competitors. If found will be terminated Immediately without any notice.
                </li>
                <li>Students studying in the institution should not participate in any illegal unlawful activities. If found in will be terminated immediately without any notice.
                </li>
                <li>Management holds the final authority to take any decisions regarding any issues raised.</li>

                <li>Management holds responsibility to change or include or exclude any terms and conditions from time to time without prior notification. By agreeing to these terms includes any new terms brought to practice from time to time.</li>
            </ol>
        </div>
    )
}

export default Terms
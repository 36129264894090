import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Foot  from './components/Footer';
import { useState } from 'react';
import { Admin } from './components/Admin';
import Error from './components/Error';
import { Register } from './components/Register';
import Logo from './Assets/whatsapp.png'
import Terms from './components/Terms';
function App() {
  const[isAuth, setIsAuth] = useState(false)
  const location = useLocation();
  const isLocate = location.pathname === '/' || location.pathname === '/registration'  || !isAuth

  return (
   <>  <div >
       {isLocate  && <Navbar isAuth={isAuth} setIsAuth={setIsAuth}/>} 
      <div style={{minHeight:'90vh'}}>
 <Routes>
        <Route path='/' element={<Home/>}></Route>
      {isAuth ?
        <Route path='/admin-panel' element={<Admin setIsAuth={setIsAuth}/>} ></Route>
       :  <Route path='/admin-panel' element={<Error Admin={true}/>} ></Route>
      }  
       <Route path='/application-form' element={<Register/>}></Route>
       <Route path='/terms-&-condition' element={<Terms/>}></Route>

       <Route path='*' element={<Error/>} ></Route>
      </Routes>
      </div>

      {isLocate  &&  <Foot/>}
    </div>

    {/* whatsapp */}
<div className='fixed-bottom right-100 p-3' style={{zIndex:'6',left:'initial'}}>
  <a href="https://wa.me/919944930018?text=Hello How can I help you ?" target='_black'>
    <img  src={Logo} alt="aa"  width='60'/>
  </a>

</div>
</>
  );
}

export default App;

import { Card, Carousel } from 'flowbite-react'
import React, { useEffect, useRef, useState } from 'react'
import { Element } from 'react-scroll'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'yet-another-react-lightbox'
import PhotoAlbum from 'react-photo-album'
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { MdOutlineMailOutline } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdAccessTime } from "react-icons/md";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Testi1 from '../Assets/Feedback_1.mp4'
import Testi2 from '../Assets/Feedback2.mp4'
import Testi3 from '../Assets/Feedback3.mp4'
import Testi4 from '../Assets/Feedback4.mp4'
import Testi5 from '../Assets/Feedback5.mp4'
import img1 from '../Assets/Gallery/img1.webp'
import img2 from '../Assets/Gallery/img2.webp'
import img3 from '../Assets/Gallery/img3.webp'
import img4 from '../Assets/Gallery/img4.webp'
import img5 from '../Assets/Gallery/img5.webp'
import img6 from '../Assets/Gallery/img6.webp'
import img7 from '../Assets/Gallery/img7.webp'
import img8 from '../Assets/Gallery/img8.webp'
import img9 from '../Assets/Gallery/img9.jpg'
import img10 from '../Assets/Gallery/img10.webp'
import img11 from '../Assets/Gallery/img11.webp'
import img12 from '../Assets/Gallery/img12.webp'
import img13 from '../Assets/Gallery/img13.jpg'
import img14 from '../Assets/Gallery/img14.jpg'
import img15 from '../Assets/Gallery/img15.jpg'
import img16 from '../Assets/Gallery/img16.webp'
import img17 from '../Assets/Gallery/img17.jpg'
import img18 from '../Assets/Gallery/img18.jpg'
import img19 from '../Assets/Gallery/img19.jpg'
import img20 from '../Assets/Gallery/img20.jpg'
import img21 from '../Assets/Gallery/img21.jpg'
import img22 from '../Assets/Gallery/img22.jpg'
import img23 from '../Assets/Gallery/img23.jpg'
import img24 from '../Assets/Gallery/img24.jpg'
import img25 from '../Assets/Gallery/img25.jpg'
import img26 from '../Assets/Gallery/img26.jpg'
import img27 from '../Assets/Gallery/img27.jpg'
import img28 from '../Assets/Gallery/img28.webp'
import img29 from '../Assets/Gallery/img29.webp'
import img30 from '../Assets/Gallery/img30.jpg'


const Home = () => {
  const [load, setLoad] = useState(false);
  const [contactUs, setContactUs] = useState({
    fName: '',
    lName: '',
    email: '',
    number: '',
    subject: '',
    message: ''
  })

  const handleSubmit = async (e) => {
    const currentDateTime = new Date().toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata', // Set the time zone to IST
    });
    setLoad(true)
    e.preventDefault();
    const { fName,
      lName,
      email,
      number,
      subject,
      message
    } = contactUs;

    try {
      const res = await fetch("https://annai-industrial-school-default-rtdb.firebaseio.com/ContactDetails.json",

        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            fName,
            lName,
            email,
            number,
            subject,
            message,
            date: currentDateTime,
          })
        })
      alert("Form Submitted Successfully!")

      setContactUs({
        fName: '',
        lName: '',
        email: '',
        number: '',
        subject: '',
        message: ''
      })

    } catch (er) {
      alert("Form not Submitted", er)
    } finally {
      setLoad(false)
    }

  }



  const [counterOn, setCounteron] = useState(false)

  const images = [
    { src: 'https://images.jdmagicbox.com/comp/kanchipuram/d2/9999pxx44.xx44.101013170328.y5d2/catalogue/annai-industrial-school-kanchipuram-railway-station-kanchipuram-fashion-designing-institutes-2md1dncom6.jpg' },
    { src: 'https://images.jdmagicbox.com/comp/kanchipuram/d2/9999pxx44.xx44.101013170328.y5d2/catalogue/annai-industrial-school-kanchipuram-railway-station-kanchipuram-fashion-designing-institutes-u48j3m115l.jpg' },
    { src: 'https://images.jdmagicbox.com/comp/kanchipuram/d2/9999pxx44.xx44.101013170328.y5d2/catalogue/annai-industrial-school-kanchipuram-railway-station-kanchipuram-fashion-designing-institutes-r4me3k3k37.jpg' },
    { src: 'https://images.jdmagicbox.com/comp/kanchipuram/d2/9999pxx44.xx44.101013170328.y5d2/catalogue/annai-industrial-school-kanchipuram-railway-station-kanchipuram-fashion-designing-institutes-01hl78jvv7.jpg' },

  ]

  const services = [
    { id: 1, title: "Computer Aided Fashion Designing", desc: 'Learn the fundamentals of fashion designing from our reputed institute and get your diploma.', src: 'https://image3.jdomni.in/library/17/39/0B/D99334B8DE3EAD77CEA4CFEB2E_1496267361534_cropped_450X450.jpeg' },
    { id: 2, title: "Hotel Management & Catering Science", desc: 'Now study Catering from any of our esteemed institutes & pass out with flying colours & a stable job in hand.', src: 'https://image2.jdomni.in/library/5D/8B/07/1C76B104479DD5D1DADCEE6A5E_1509432620047_cropped_450X450.jpeg' },
    { id: 3, title: "Fashion Designing", desc: 'We are among the leading institutes offering uniquely designed courses for Fashion Designing.', src: 'https://image3.jdomni.in/library/1A/B5/74/315F41448A2264F6B5B0889B83_1496771297003_cropped_450X450.jpeg' },
    { id: 4, title: "Medical Laboratory Technology", desc: 'Now study Lab Technology from our esteemed institutes & pass out with flying colours and a stable job in hand.', src: 'https://image2.jdomni.in/library/0E/72/FF/75F8EE3AA90AA768DF8207CC87_1496247830915_cropped_450X450.jpeg' },
    { id: 5, title: "Patient Care", desc: 'Now study Nursing from any of our esteemed institutes & pass out with flying colours and a stable job in hand.', src: 'https://image3.jdomni.in/library/D2/E0/46/46D78F7E9B387BC8FEA5E31A7D_1509371630186_cropped_450X450.jpeg' },
    { id: 6, title: "Beauty Technician", desc: 'We are one of the best beauty institutes & are devoted & committed to excellence in education and knowledge.', src: 'https://image3.jdomni.in/banner/27032023/8C/AD/5A/0414DD8E6CC1C4F1261BE10E75_1679933328308.jpg?output-format=webp' },
    { id: 7, title: "X-Ray Technician", desc: 'Our learning centre offers the best courses that are  structured to ease out the teaching process.', src: 'https://image1.jdomni.in/banner/27032023/6E/16/92/91CAB1915CD682F520178FFF61_1679933505052.jpg?output-format=webp' },
    { id: 8, title: "OT Technician", desc: 'We proudly provide certificate course that the students can get easy job offer with this course.', src: 'https://image2.jdomni.in/banner/27032023/D8/B1/6F/7B50D81EB38BAC8DDBB2FED2F2_1679933602031.jpg?output-format=webp' },

  ]

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  const [index, setIndex] = useState(-1);

  const photos = [
    { id: 1, src: img1, width: 1080, height: 900 },
    { id: 2, src: img2, width: 1080, height: 900 },
    { id: 3, src: img3, width: 1080, height: 900 },
    { id: 4, src: img4, width: 1080, height: 900 },
    { id: 5, src: img5, width: 1080, height: 900 },
    { id: 6, src: img6, width: 1080, height: 900 },

    { id: 7, src: img7, width: 1080, height: 900 },
    { id: 8, src: img8, width: 1080, height: 900 },
    { id: 9, src: img9, width: 1080, height: 900 },
    { id: 10, src: img10, width: 1080, height: 900 },
    { id: 11, src: img11, width: 1080, height: 900 },
    { id: 12, src: img12, width: 1080, height: 900 },

    { id: 13, src: img13, width: 1080, height: 900 },
    { id: 14, src: img14, width: 1080, height: 900 },
    { id: 15, src: img15, width: 1080, height: 900 },
    { id: 16, src: img16, width: 1080, height: 900 },
    { id: 17, src: img17, width: 1080, height: 900 },
    { id: 18, src: img18, width: 1080, height: 900 },

    { id: 19, src: img19, width: 1080, height: 900 },
    { id: 20, src: img20, width: 1080, height: 900 },
    { id: 21, src: img21, width: 1080, height: 900 },
    { id: 22, src: img22, width: 1080, height: 900 },
    { id: 23, src: img23, width: 1080, height: 900 },
    { id: 24, src: img24, width: 1080, height: 900 },

    { id: 25, src: img25, width: 1080, height: 900 },
    { id: 26, src: img26, width: 1080, height: 900 },
    { id: 27, src: img27, width: 1080, height: 900 },
    { id: 28, src: img28, width: 1080, height: 900 },
    { id: 29, src: img29, width: 1080, height: 900 },
    { id: 30, src: img30, width: 1080, height: 900 },
  ]

  const renderCarouselSlides = () => {
    const slides = [];

    for (let i = 0; i < photos.length; i += 6) {
      const slidePhotos = photos.slice(i, i + 6);
      const sizes = window.innerWidth < 1014 ? 2 : 3

      slides.push(
        <div key={i} className="carousel-slide">
          <div className="flex justify-center">
            {slidePhotos.slice(0, sizes).map((photo, photoIndex) => (
              <div key={photoIndex} className={`${window.innerWidth < 1014 ? 'w-1/2' : 'w-1/3'} m-3`} data-aos="zoom-in">
                <PhotoAlbum
                  key={photoIndex}
                  photos={[photo]}
                  layout="rows"
                  targetRowHeight={200}
                  onClick={() => setIndex(i + photoIndex)}
                />
                {/*      <div className='overlay'>
                  <div className='content'>
                    <h3 className='text-3xl '>{photo.title}</h3>
                  </div>
                </div> */}
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            {slidePhotos.slice(sizes, sizes + sizes).map((photo, photoIndex) => (
              <div key={photoIndex} className={`${window.innerWidth < 1014 ? 'w-1/2' : 'w-1/3'} m-3`} data-aos="zoom-in">
                <PhotoAlbum
                  key={photoIndex}
                  photos={[photo]}
                  layout="rows"

                  targetRowHeight={200}
                  onClick={() => setIndex(i + 3 + photoIndex)}
                />     {/* <div className='overlay'>
                <div className='content'>
                  <h3 className='text-3xl '>{photo.title}</h3>
                </div>
              </div> */}

              </div>
            ))}
          </div>
        </div>
      );
    }

    return slides;
  };

  const slickSettings = {
    dots: true, // Show dots only if not on mobile
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '0',
  };


  const videos = [
    { id: 1, src: 'https://www.youtube.com/embed/fEU5YXj0jMU?si=tFK4Vllpyp9VxfxV', title: 'Beautician - Types of Hair Cut Class' },
    { id: 2, src: 'https://www.youtube.com/embed/yp36Khk9tJM?si=qLNR4D423-3UU_s2', title: 'Learning Blood Collection' },
    { id: 3, src: 'https://www.youtube.com/embed/KbyZye8Zggw?si=28x1lna-Pg_SvP2w', title: 'Free Phone to all Students' },
    { id: 4, src: 'https://www.youtube.com/embed/HnSPxJdqnew?si=Pda46OsfF-3xtTyS', title: 'Students Feedback-2023' },
    { id: 5, src: 'https://www.youtube.com/embed/vOAHSlCiobg?si=bf6FCJtCbps_RSua', title: 'Annai Activities-2023' },
    { id: 6, src: 'https://www.youtube.com/embed/XwKPDI8WQgU?si=BxeovRmez0T-zVyi', title: 'Free Tour of Students' },
    { id: 7, src: 'https://www.youtube.com/embed/aV-uEz06Tio?si=hqfW9m3RlALHej2n', title: 'Injection Practice by Annai Patient Care Students' },
    { id: 8, src: 'https://www.youtube.com/embed/3mXL7DJPQyE?si=oW3TAf1uChuBaoVA', title: 'Annai Food Fest-2023' },
    { id: 9, src: 'https://www.youtube.com/embed/UpVi5fKBSJM?si=bzshtTjx_tYXb7NJ', title: 'Annai Fashion Show' },
    { id: 10, src: 'https://www.youtube.com/embed/xQ3ah2c58kk?si=IChJet6DKJ5I5wIe', title: 'Mehendi Session' },
    { id: 11, src: 'https://www.youtube.com/embed/OEIwQokXGVI?si=K9yd3NpcGwNdtcNn', title: 'Annai Hotel Management - Advertisement' },
    { id: 12, src: 'https://www.youtube.com/embed/L5mttY_vvI4?si=AkWsqHKy7utqSet3', title: 'Annai Fashion Designing -Advertisement' },
    { id: 13, src: 'https://www.youtube.com/embed/YpcCHdPOrvA?si=7OpumPaClCJ970uQ', title: 'Annai Industrial School-Advertisement' },

  ]

  const renderCarouselVideo = () => {
    const slides = [];
    const sizes = window.innerWidth < 1014 ? 2 : 4

    for (let i = 0; i < videos.length; i += sizes) {
      const videoRow = videos.slice(i, i + sizes);

      slides.push(
        <div key={i} className='VideoCarousel'>
          {videoRow.map((video) => (
            <div key={video.id} className="w-1/2 p-2 SingleV" data-aos="zoom-in">
              <iframe
                className="rounded-lg"
                style={{ borderRadius: '8px' }}
                title={`YouTube Video ${video.id}`}
                width="100%"
                height={window.innerWidth < 1104 ? "200" : window.innerWidth < 1500 ? "245" : "280"}
                src={video.src}
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <h6 style={{ textAlign: 'center', paddingTop: '5px' }}>{video.title}</h6>
            </div>
          ))}
        </div>
      );
    }

    return slides;
  };

  const slickSettingsVideo = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  };

  const slickTestiSetting = {
    dots: true,
    autoplay:true,
    autoplaySpeed:1500,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show in lg screen
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Number of slides to show in md screen
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Number of slides to show in sm screen
        },
      },
    ],
  };


  const Testi = [
    { src: Testi1 },
    { src: Testi2 },
    { src: Testi3 },
    { src: Testi4 },
    { src: Testi5 },

  ]

  /* Testinomial */

  const carouselRef = useRef(null);

  useEffect(() => {
    const options = {
      items: 1,
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000, // 2 seconds
    };

    if (carouselRef.current) {
      carouselRef.current.setOptions(options);
    }
  }, []);
  
  return (
    <div className='bg-neutralSilver '>   <div >
      <div className=' max-w-screen-2x1' style={{
        height: window.innerWidth < 500 ? '30vh'
          : window.innerWidth < 880
            ? '45vh'
            : '75vh'
      }}>
        <Carousel slideInterval={5000} className='w-full mx-auto'>
          {
            images.map((img, id) => (
              <img src={img.src} key={id} style={{ objectPosition: 'center', objectFit: 'cover', width: '100%', height: '100%' }} />
            ))}

        </Carousel>

      </div>
    </div>


      {/* services */}

      <Element className='container-md' name='services'>
        <div className='text-center my-4 '>
          <h2 className='text-4xl text-neutralDGray font-semibold mb-2' data-aos="fade-up">
            Services
          </h2>
        </div>

        <div className="flex items-center justify-center">
          <div className="grid grid-cols-2  sm:grid-cols-2  gap-5 md:grid-cols-2 lg:grid-cols-4">
            {services.map(item => (
              <div data-aos="fade-up" key={item.id} className="rounded-lg cards group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                <div className={`${window.innerWidth < 500 ? 'h-90' : 'h-96'}  w-72`}>
                  <img className="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={item.src} alt="" />
                </div>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                <div className={`${window.innerWidth < 500 ? 'px-2' : 'px-9'}  absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center  text-center transition-all duration-500 group-hover:translate-y-0`}>
                  <h1 className={`font-dmserif ${window.innerWidth < 500 ? 'text-1xl ' : 'text-2xl '} font-bold text-white`}>{item.title}</h1>
                  <p className="mb-3 text-lg italic text-white opacity-0  transition-opacity duration-300 " style={{ fontSize: window.innerWidth < 500 ? '12px' : '' }}>{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>



      </Element>


      {/* About us */}
      <Element className='container-md' name='about-us'>
        <div className='text-center mt-10'>
          <h2 className='text-4xl text-neutralDGray font-semibold mb-2' data-aos="fade-up">
            About Us
          </h2>
        </div>
        <div className='lg:flex pb-6'>
          <div className='lg:w-1/2 sm:w-full p-3 flex flex-col h-full'>
            <p className='text-base text-gray900 flex-1' style={{ textAlign: 'justify', lineHeight: '2' }}>
              We, Annai Industrial School at in Kanchipuram, Tamil Nadu, are focused on acquiring knowledge rather than imparting knowledge wherein teachers act as facilitators. The learning process is made more enjoyable by adopting an unconventional methodology of education where students are encouraged to learn through research & model-making, leading to greater retention. Our aim is to ensure all-round development of the student's personality.
            </p>
          </div>
          <div className='lg:w-1/2 sm:w-full p-3 flex h-full'>
            <img style={{ height: window.innerWidth < 1300 ? '250px' : '300px' }} className="shadow-lg rounded-lg object-cover  w-full" src="https://image3.jdomni.in/banner/15092022/2E/D0/3F/DE83BF36ECBD262CBD6C719CAE_1663224466305.png?output-format=webp" alt="Education Image" />
          </div>
        </div>
        <h2 className='text-4xl text-neutralDGray font-semibold mb-2' data-aos="fade-up">
          Our Vision & Mission
        </h2>
        <div className="row m-0 mt-5">
          <div className="col-lg-6 my-2 col-sm-12">
            <div className="flip-container" data-aos="flip-right">
              <div className="flip-box">
                <div className="front d-flex flex-column align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth="1.5"
                    stroke="currentColor" className="w-11 h-11">
                    <path strokeLinecap="round" strokeLinejoin="round"
                      d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                  </svg>

                  <h1 className='text-4xl' style={{ fontWeight: '700', color: 'white' }}>Vision</h1>
                </div>
                <div className="back d-flex flex-column align-items-center justify-content-center" >
                  <h3 style={{ fontWeight: '700', color: '#FF9800' }}>Vision</h3>
                  <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                    Empowering minds, shaping futures – Our college envisions becoming a beacon of transformative education, fostering innovation, and nurturing global leaders poised to make a positive impact on society.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 my-2  col-sm-12">
            <div className="flip-container" data-aos="flip-right">
              <div className="flip-box">
                <div className="front d-flex flex-column align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="30" height="30" strokeWidth="1.5" stroke="currentColor" className="w-11 h-11">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                  </svg>

                  <h2 className='text-4xl' style={{ fontWeight: '700', color: 'white' }}>Mission</h2>
                </div>
                <div className="back d-flex flex-column align-items-center justify-content-center" >
                  <h4 style={{ fontWeight: '700', color: '#FF9800' }}>MISSION</h4>
                  <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                    Dedicated to excellence in education, our mission is to provide a dynamic learning environment that encourages intellectual growth, instills ethical values, and prepares students to thrive in a rapidly evolving world. We are committed to creating a community that fosters curiosity, critical thinking, and a passion for lifelong learning.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>






      <div className='BgHomeImg my-2 flex items-center justify-center'>
        <ScrollTrigger className='w-full' onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>

          {counterOn && (
            <div className="grid py-4 justify-items-center items-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 container-md">
              <div className="col-span-1 md:col-span-1 lg:col-span-1 flex justify-center w-full">
                <div className='border shadow-lg rounded-lg m-3 p-3 flex flex-col just-center justify-center' style={{ width: '90%', minHeight: '200px' }}>
                  <h1 className='text-center text-yellow-300 text-7xl font-bold'><CountUp start={0} end={3} duration={2} delay={0} /></h1>
                  <h3 className='text-center text-white'>Years in Education</h3>
                </div>
              </div>

              <div className="col-span-1 md:col-span-1 lg:col-span-1 flex justify-center w-full">
                <div className='border  shadow-lg rounded-lg m-3 p-3 flex flex-col items-center justify-center' style={{ width: '90%', minHeight: '200px' }}>
                  <h1 className='text-center text-yellow-300  text-7xl font-bold'><CountUp start={0} end={8} duration={2} delay={0} /></h1>
                  <h3 className='text-center text-white'>Courses</h3>
                </div>
              </div>

              <div className="col-span-1 md:col-span-1 lg:col-span-1 flex justify-center w-full">
                <div className='border  shadow-lg rounded-lg m-3 p-3 flex flex-col items-center justify-center' style={{ width: '90%', minHeight: '200px' }}>
                  <h1 className='text-center text-yellow-300  text-7xl font-bold'><CountUp start={0} end={200} duration={2} delay={0} />+</h1>
                  <h3 className='text-center text-white'>Rank Holders</h3>
                </div>
              </div>
            </div>
          )}
        </ScrollTrigger>
      </div>


      {/* Gallery */}
      <Element className='container-md' name='gallery' >
        <div className='text-center mt-10' id='gallery'>
          <h2 className='text-4xl text-neutralDGray font-semibold mb-2' data-aos="fade-up">
            Gallery
          </h2>
        </div>

        <div className="carousels">
          <Slider {...slickSettings}>
            {renderCarouselSlides()}
          </Slider>
        </div>


        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
          overlayStyles={{
            background: 'rgba(10, 10, 10, 1.3)',
          }}
        />
      </Element>


      {/* Videos */}
      <Element className='container-md' name='videos'>
        <div className='text-center mt-10'>
          <h2 className='text-4xl text-neutralDGray font-semibold mb-2' data-aos="fade-up">
            Videos
          </h2>
        </div>
        <div>

          <Slider {...slickSettingsVideo} className="carousel">
            {renderCarouselVideo()}
          </Slider>
        </div>
      </Element>


      {/* Testinomial */}
      <Element className='bgTesti' name='testinomial'>
        <div className="container-md">
          <div className='text-center mt-10' >
            <h2 className='text-4xl text-neutralDGray font-semibold mb-2' data-aos="fade-up">
              Testinomials
            </h2>
          </div>
          <div className="container  mx-auto flex flex-col items-center pb-6  md:p-5 md:px-12">
            <h1 className="text-2xl font-semibold text-neutralGray text-center">What our students are saying about us</h1>
          </div>

       <Slider {...slickTestiSetting} className="carousel Testi-Carousel">
      {Testi.map((item, id) => (
        <div key={id} className="flowbite-carousel-item ">
          <video controls className="rounded-lg mx-3" data-aos="fade-up">
            <source src={item.src} type="video/mp4" />
            Your browser does not support this video
          </video>
        </div>
      ))}
    </Slider>
    
        </div>
      </Element>



      {/* Contact Us */}
      <Element className='container-md my-5' name='contact'>
        <div className='text-center mt-10' >
          <h2 className='text-4xl text-neutralDGray font-semibold mb-2' data-aos="fade-up">
            Contact Us
          </h2>
        </div>

        <div className="my-5 ">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4">
            <div data-aos="zoom-in" style={{ background: '#0098fe', minHeight: '160px' }} className="mt-5 parentdiv rounded-lg py-5 flex flex-col items-center justify-between">
              <div className="p-3 rounded-circle conHover" style={{ marginTop: '-80px', border: '5px solid white', borderRadius: '50%', background: '#0098fe' }}>
                <MdOutlineLocationOn fontSize='30px' color='white' />
              </div>

              <h5 className="m-0 py-2 text-center conHovers  text-2xl text-brandPrimary" style={{ fontWeight: '700' }}>Address</h5>
              <h6 className="m-0 py-2 text-center" style={{ color: 'white', fontWeight: '700' }}>
                No 111 A,Opposite to Narayanaguru School,Ekambaranathar Koil Sannathi Street, <br /> Kanchipuram Railway Station, Kanchipuram, Tamil Nadu 631502
              </h6>
            </div>

            <div data-aos="zoom-in" style={{ background: '#0098fe', minHeight: '160px' }} className="mt-5 parentdiv rounded-lg py-5 flex flex-col items-center justify-between">
              <div className="p-3 " style={{ marginTop: '-80px', border: '5px solid white', borderRadius: '50%', background: '#0098fe' }}>
                <MdOutlineMailOutline fontSize='30px' color='white' />
              </div>

              <h5 className="m-0 py-2 conHovers  text-2xl text-brandPrimary" style={{ fontWeight: '700' }}>E-Mail</h5>
              <h6 className="m-0 py-2" style={{ color: 'white', fontWeight: '700' }}>ahmcis@yahoo.co.in</h6>
              <h6 className="m-0 py-2" style={{ color: 'white', fontWeight: '700' }}>annaikpm@gmail.com</h6>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 ">
            <div data-aos="zoom-in" style={{ background: '#0098fe', minHeight: '160px' }} className="mt-5 parentdiv rounded-lg py-5 flex flex-col items-center justify-between">
              <div className="p-3 " style={{ marginTop: '-80px', border: '5px solid white', borderRadius: '50%', background: '#0098fe' }}>
                <FiPhoneCall fontSize='30px' color='white' />

              </div>

              <h5 className="m-0 py-2 conHovers text-2xl text-brandPrimary" style={{ fontWeight: '700' }}>Contact Number</h5>
              <h6 className="m-0 py-2" style={{ color: 'white', fontWeight: '700' }}>+91-9944930018, +91-9944964143 <br /> +91-9944960143, +91-7867090756  <br /> +91-7010407183</h6>
            </div>

            <div data-aos="zoom-in" style={{ background: '#0098fe', minHeight: '160px' }} className="mt-5 parentdiv rounded-lg py-5 flex flex-col items-center justify-between">
              <div className="p-3 rounded-circle conHover  text-2xl text-brandPrimary" style={{ marginTop: '-80px', border: '5px solid white', borderRadius: '50%', background: '#0098fe' }}>
                <MdAccessTime fontSize='30px' color='white' style={{ background: '#0098fe' }} />

              </div>

              <h5 className="m-0 py-2 conHovers  text-2xl text-brandPrimary" style={{ fontWeight: '700' }}>Our Office Address</h5>
              <h6 className="m-0 py-2" style={{ color: 'white', fontWeight: '700' }}>Mon - Sun : Open 24 hrs</h6>

            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 gap-4">
          <div>
            <h2 className='text-2xl text-neutralDGray font-semibold mb-2 mt-8' data-aos="fade-up">
              Location
            </h2>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.0048709649323!2d79.6994377!3d12.842961500000008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52c252136ef407%3A0x4dcc9551556881a7!2sAnnai%20Industrial%20School!5e0!3m2!1sen!2sin!4v1708347977167!5m2!1sen!2sin" allowFullScreen="" style={{ width: '100%', minHeight: '330px' }} className='rounded shadow ' loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div>
            <h2 className='text-2xl text-neutralDGray font-semibold mb-2 mt-8' data-aos="fade-up">
              Drop Your Queries Here
            </h2>
            <form className='pb-6' onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:mt-1 items-center">
                <div className="col-span-1 sm:col-span-2 lg:col-span-1">
                  <label htmlFor="Fname" className="CForm">First Name *</label> <br />
                  <input value={contactUs.fName} onChange={(e) => setContactUs({ ...contactUs, fName: e.target.value })} type="text" required className="px-3 w-full rounded border border-black-500 focus:border-black-700 outline-none" name="Fname" />
                </div>
                <div className="col-span-1 sm:col-span-2 lg:col-span-1  sm:mt-1">
                  <label htmlFor="Lname" className="CForm">Last Name</label> <br />
                  <input value={contactUs.lName} onChange={(e) => setContactUs({ ...contactUs, lName: e.target.value })} type="text" className="px-3 w-full rounded border border-black-500 focus:border-black-700 outline-none" name="Lname" />
                </div>
              </div>

              <div className="grid grid-cols-1  md:grid-cols-2 gap-4 mt-2">
                <div className="col-span-1 sm:col-span-2 lg:col-span-1">
                  <label htmlFor="MobileNumber" className="CForm">Mobile Number *</label> <br />
                  <input value={contactUs.number} onChange={(e) => setContactUs({ ...contactUs, number: e.target.value })} type="text" required className="px-3 w-full rounded border border-black-500 focus:border-black-700 outline-none" name="MobileNumber" />
                </div>
                <div className="col-span-1 sm:col-span-2 lg:col-span-1  sm:mt-0">
                  <label htmlFor="EmailAddress" className="CForm">Email Address *</label> <br />
                  <input value={contactUs.email} onChange={(e) => setContactUs({ ...contactUs, email: e.target.value })} type="text" required className="px-3 w-full rounded border border-black-500 focus:border-black-700 outline-none" name="EmailAddress" />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                <div className="col-span-1 sm:col-span-2 lg:col-span-1">
                  <label htmlFor="Subject" className="CForm">Subject *</label> <br />
                  <input type="text" value={contactUs.subject} onChange={(e) => setContactUs({ ...contactUs, subject: e.target.value })} required className="px-3 w-full rounded border border-black-500 focus:border-black-700 outline-none" name="Subject" />
                </div>
                <div className="col-span-1 sm:col-span-2 lg:col-span-1 sm:mt-2">
                  <label htmlFor="Message" className="CForm">Message *</label> <br />
                  <textarea type="text" value={contactUs.message} onChange={(e) => setContactUs({ ...contactUs, message: e.target.value })} required className="px-3 w-full rounded border border-black-500 focus:border-black-700 outline-none" name="Message"></textarea>
                </div>
              </div>

              <button type='submit' className='rounded mt-3 bg-blue-500 text-white px-4 py-2 w-full hover:bg-black-700 transition duration-300'>Submit</button>
            </form>
          </div>
        </div>


      </Element>
    </div>

  )
}

export default Home
import React, { useEffect, useState } from 'react'
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, orderByKey, limitToLast, get, query } from 'firebase/database';
import { animateScroll as scroll } from 'react-scroll';
import { Modal } from 'flowbite-react';

export const Register = () => {
  const Navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [applicationNumber, setApplicationNumber] = useState('');
  const [loadApp, setLoadApp] = useState(false)
  const [appError, setAppError] = useState(null)
  const [openModal, setOpenModal] = useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const fetchLastApplicationNumber = async () => {
    try {
      setLoadApp(true)
      const db = getDatabase();
      const detailsRef = ref(db, 'Details');
      const lastDetailsQuery = query(detailsRef, orderByKey(), limitToLast(1));
      const snapshot = await get(lastDetailsQuery);

      if (snapshot.exists()) {
        const lastKey = Object.values(snapshot.val())[0];
        const number = parseInt(lastKey.appNumber.slice(9));
        const currentYear = new Date().getFullYear();
        console.log(number)
        const newApplicationNumber = `ANI/${currentYear}/${(number + 1).toString().padStart(4, '0')}`;
        setApplicationNumber(newApplicationNumber);
      }
      else {
        const currentYear = new Date().getFullYear();
        const newApplicationNumber = `ANI/${currentYear}/0001`;
        setApplicationNumber(newApplicationNumber);
      }
    } catch (error) {
      setAppError(error.message)
      console.error('Error fetching last application number:', error);
    }
    finally {
      setLoadApp(false)
    }
  };

  useEffect(() => {
    fetchLastApplicationNumber();
    scrollToTop()
  }, []);


  //Registration form
  const [details, setDetails] = useState({
    name: '',
    fatherName: '',
    fatherOccupation: '',
    DOB: '',
    age: '',
    community: '',
    nationality: '',
    address: '',
    number: '',
    subCaste: '',
    email: '',
    gender: '',
    pincode: '',
    educationDetails: [],
  })

const[payments,setPayment]=useState(false)
const[paymentId,setPaymentId]=useState('')
  const [checked, setChecked] = useState(false)
  


  const handleSubmit = async (e) => {
    e.preventDefault();
   /*  // Razorpay Integration
    var options = {
      key: "rzp_test_ot5gzMNpFvy2fh",
      key_secret: "5UyEgEdltDNpf9l3KukKLclh",
        order_id:"order_NjTNiwzMB9wWe0",
      amount: '10000',
      currency: "INR",
      name: "Annai Industrial School",
      description: "Test Transaction",
      image: logo,

      prefill: {
        name: details.name,
        email: details.email,
        contact: details.number,
      },
      notes: {
        address: details.address,
      },
      theme: {
        color: "#3399cc",
      },
      handler: function (res) {
      
          settingPayment(res.razorpay_payment_id)
          console.log(`Payment Success:\nPayment ID:`);
          console.log(res)
          console.log(details)
      
        else{
          setDetails(prevDetails => ({
            ...prevDetails,
            payment: false,
            payment_id: ''
          }));
          setOpenModal(true);
          console.log(`Payment Failed:\nCode: ${res.error.code}\nDescription: ${res.error.description}\nSource: ${res.error.source}\nStep: ${res.error.step}\nReason: ${res.error.reason}\nOrder ID: ${res.error.metadata.order_id}\nPayment ID: ${res.error.metadata.payment_id}`);
          submitFormToFirebase();
        } 
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open(); */
    submitFormToFirebase()
  };

 
  // Form set to Firebase
  const submitFormToFirebase = async () => {
    const currentDateTime = new Date().toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata',
    });

    setLoad(true);

  
    const {
      name,
      fatherName,
      fatherOccupation,
      DOB,
      age,
      community,
      nationality,
      address,
      number,
      subCaste,
      gender,
      pincode,
      email,
      educationDetails,
    } = details;

    try {
      const res = await fetch("https://annai-industrial-school-default-rtdb.firebaseio.com/Details.json", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          appNumber: applicationNumber,
          razorpay_payment_id: paymentId,
          payment: payments,
          name,
          fatherName,
          fatherOccupation,
          DOB,
          age,
          community,
          nationality,
          address,
          number,
          subCaste,
          email,
          gender,
          pincode,
          educationDetails,
          date: currentDateTime,
        }),
      });

      alert("Form Submitted Successfully!");
      fetchLastApplicationNumber();

    } catch (er) {
      alert("Form not Submitted", er);
    } finally {
      setLoad(false);
    }
  };

  const addEducationRow = () => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      educationDetails: [...prevDetails.educationDetails, {}],
    }));
  };

  const deleteEducationRow = () => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      educationDetails: prevDetails.educationDetails.slice(0, -1),
    }));
  };
  const handleEducationChange = (index, key, value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      educationDetails: prevDetails.educationDetails.map((edu, i) =>
        i === index ? { ...edu, [key]: value } : edu
      ),
    }));
  };

  const handleInputChange = (field, value) => {
    setDetails(prevDetails => ({
      ...prevDetails,
      [field]: value
    }));
  };

  /* GPAY */


  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script)
  //   }
  // }, [])


  const closeModel = () => {
    setOpenModal(false)
    setDetails({
      name: '',
      fatherName: '',
      fatherOccupation: '',
      DOB: '',
      age: '',
      community: '',
      nationality: '',
      address: '',
      number: '',
      subCaste: '',
      email: '',
      gender: '',
      pincode: '',
      educationDetails: []
    });
  }

  return (
    <>
      <Modal show={openModal} onClose={closeModel} style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Modal.Header className='CardHeader'>Payment Info</Modal.Header>
        <Modal.Body >
          {details && payments ? (
            <>

              <h2 className='text-7xl text-center m-3  text-light text-center '><span className='rounded-circle bg-success p-4'>&#10003;</span> </h2>

              <h2 className='text-2xl text-success text-center p-3'>Payment Success</h2>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
                <div>
                  <p>Name: {details.name}</p>
                  <p>Father Name: {details.fatherName}</p>
                  <p>Date of Birth: {details.DOB}</p>
                  <p>Email: {details.email}</p>
                  <p>Community: {details.community}</p>
                  <p>Nationality: {details.nationality}</p>
                  <p>Address: {details.address}</p>

                </div>
                <div>
                  <p className='sm:hidden hidden lg:flex md:flex'>&nbsp;</p>
                  <p>Father Occupation: {details.fatherOccupation}</p>
                  <p>Age: {details.age}</p>
                  <p>Gender: {details.gender}</p>
                  <p>Subcaste: {details.subCaste}</p>
                  <p>Contact Number: {details.number}</p>
                  <p>Pincode: {details.pincode}</p>

                </div>
              </div>


              <div className=" mt-3" style={{ width: window.innerWidth < 700 ? '300px' : '100%', overflowX: 'auto' }} >
                {(details.educationDetails.length) ? (
                  <table className=" min-w-full bg-white border border-gray-300 ">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 border-b sm:w-1/6">S.No.</th>
                        <th className="px-6 py-3 border-b sm:w-2/6">Educational Qualification</th>
                        <th className="px-6 py-3 border-b sm:w-2/6">Board/University</th>
                        <th className="px-6 py-3 border-b sm:w-2/6">Name of School</th>
                        <th className="px-6 py-3 border-b sm:w-1/6">Year of Passing</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (details.educationDetails).map((list, id) => (
                          <tr key={id} className="bg-white hover:bg-gray-100">
                            <td className="px-6 py-4 sm:w-1/6">{id + 1}</td>
                            <td className="px-6 py-4 sm:w-1/6">{list.qualification}</td>
                            <td className="px-6 py-4 sm:w-1/6">{list.board}</td>
                            <td className="px-6 py-4 sm:w-1/6">{list.schoolName}</td>
                            <td className="px-6 py-4 sm:w-1/6">{list.yearOfPassing}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>) : <div></div>}
              </div>

            </>

          ) :

            <div>
              <h2 className='text-7xl text-center mb-5 mt-3  text-light text-center '><span className='rounded-circle bg-danger p-4'>&#10006; </span> </h2>
              <div style={{ border: '1px solid red', padding: '4px' }} className='my-3'>
                <h2 className='text-1xl text-center p-3 text-danger' style={{ fontWeight: '700' }}>Oops! Something went wrong.</h2>
                <h2 className='text-1xl text-center px-3 text-danger'>While trying to reserve money from your account</h2>
              </div>
              <button className='w-full btn bg-warning text-black text-center' onClick={closeModel}>Try again</button>
            </div>}
        </Modal.Body>
      </Modal>

      <div className="mt-5">
        <div className='container px-4 sm:px-6 mx-auto lg:w-3/4'>
          <h1 className='text-center py-3 w-100 text-center  text-4xl text-brandPrimary font-semibold'>BSS DIPLOMA IN MEDICAL LABORATORY TECHNOLOGY</h1>

          <div className='flex items-center mt-10' style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <button style={{ borderRadius: '50%' }} className='bg-silver-200 border' onClick={() => Navigate('/')}><IoArrowBackOutline style={{ fontSize: '30px', padding: '3px' }} /> </button>
            <h1 className='py-3 w-100 text-center  text-2xl text-brandPrimary font-semibold'>APPLICATION FORM</h1>

            <div></div>
          </div>
          <div class=" pb-8 sm:mb-0">
            <label for="name" class="flex justify-end text-gray-700 text-sm font-bold mb-2 items-center">Application No:&nbsp;{loadApp ? <span role="status" className=''>
              <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </span> : appError ? <span>{appError}</span> : `${applicationNumber}`}</label>
          </div>

          <form onSubmit={handleSubmit}>
            <div class=" mb-4 sm:mb-0">
              <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Name of the Applicant:</label>
              <input
                required
                value={details.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
                type="text"
                id="name"
                name="name"
                className="w-full px-3 py-2 border rounded-md"
              />
            </div>
            <div className='grid lg:grid-cols-2 lg:gap-5'>

              <div class=" mb-4 sm:mb-0">
                <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Father's Name:</label>
                <input required value={details.fatherName} onChange={(e) => handleInputChange('fatherName', e.target.value)} type="text" id="name" name="name" class="w-full px-3 py-2 border rounded-md" />
              </div>
              <div class=" mb-4 sm:mb-0">
                <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Father's Occupation:</label>
                <input required value={details.fatherOccupation} onChange={(e) => handleInputChange('fatherOccupation', e.target.value)} type="text" id="name" name="name" class="w-full px-3 py-2 border rounded-md" />
              </div>

            </div>
            <div className='grid  lg:grid-cols-2 lg:gap-5'>
              <div class=" mb-4 sm:mb-0">
                <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Date of Birth:</label>
                <input required value={details.DOB} onChange={(e) => handleInputChange('DOB', e.target.value)} type="date" id="name" name="name" class="w-full px-3 py-2 border rounded-md" />
              </div>
              <div class=" mb-4 sm:mb-0">
                <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Age:</label>
                <input required value={details.age} onChange={(e) => handleInputChange('age', e.target.value)} type="number" id="age" name="age" class="w-full px-3 py-2 border rounded-md" />
              </div>
            </div>
            <div className='grid lg:grid-cols-2 lg:gap-5'>
              <div class=" mb-4 sm:mb-0">
                <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Email Id:</label>
                <input required value={details.email} onChange={(e) => handleInputChange('email', e.target.value)} type="email" id="email" name="email" class="w-full px-3 py-2 border rounded-md" />
              </div>
              <div class=" mb-4 sm:mb-0">
                <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Gender:</label>
                <input required value="Male" onChange={(e) => setDetails({ ...details, gender: e.target.value })} type="radio" id="gender" name="gender" style={{ width: '15px', height: '15px', cursor: 'pointer' }} /> Male &nbsp; &nbsp;
                <input required value="Female" onChange={(e) => setDetails({ ...details, gender: e.target.value })} type="radio" id="gender" name="gender" style={{ width: '15px', height: '15px', cursor: 'pointer' }} /> Female

              </div>
            </div>
            <div className='grid  lg:grid-cols-2 lg:gap-5'>
              <div class=" mb-4 sm:mb-0">
                <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Community:</label>
                <input required value={details.community} onChange={(e) => handleInputChange('community', e.target.value)} type="text" id="commmunity" name="commmunity" class="w-full px-3 py-2 border rounded-md" />
              </div>
              <div class=" mb-4 sm:mb-0">
                <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Subcaste:</label>
                <input required value={details.subCaste} onChange={(e) => handleInputChange('subCaste', e.target.value)} type="text" class="w-full px-3 py-2 border rounded-md" />
              </div>
            </div>
            <div className='grid  lg:grid-cols-2 lg:gap-5'>
              <div class=" mb-4 sm:mb-0">
                <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Nationality:</label>
                <input required value={details.nationality} onChange={(e) => handleInputChange('nationality', e.target.value)} type="type" id="subcaste" name="subcaste" class="w-full px-3 py-2 border rounded-md" />
              </div>
              <div class=" mb-4 sm:mb-0">
                <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Contact No:</label>
                <input required value={details.number} onChange={(e) => handleInputChange('number', e.target.value)} type="text" id="number" class="w-full px-3 py-2 border rounded-md" />
              </div>
            </div>
            <div className='grid  lg:grid-cols-2 lg:gap-5'>
              <div class=" mb-4 sm:mb-0">
                <label for="address" class="block text-gray-700 text-sm font-bold mb-2">Address:</label>
                <textarea required value={details.address} onChange={(e) => handleInputChange('address', e.target.value)} type="text" id="address" name="address" class="w-full px-3 py-2 border rounded-md" > </textarea>
              </div>
              <div class=" mb-4 sm:mb-0">
                <label for="number" class="block text-gray-700 text-sm font-bold mb-2">Pincode:</label>
                <input required value={details.pincode} onChange={(e) => handleInputChange('pincode', e.target.value)} type="number" class="w-full px-3 py-2 border rounded-md" />
              </div>
            </div>
            <h1 className='py-3 w-100 text-1xl text-brandPrimary font-semibold'>EDUCATION DETAIL</h1>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-300 ">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b sm:w-1/6">S.No.</th>
                    <th className="px-6 py-3 border-b sm:w-2/6">Educational Qualification</th>
                    <th className="px-6 py-3 border-b sm:w-2/6">Board/University</th>
                    <th className="px-6 py-3 border-b sm:w-2/6">Name of School</th>
                    <th className="px-6 py-3 border-b sm:w-1/6">Year of Passing</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(details.educationDetails) &&
                    details.educationDetails.map((edu, index) => (
                      <tr key={index} className="bg-white hover:bg-gray-100">
                        <td className="px-6 py-4 sm:w-1/6">{index + 1}</td>
                        <td className="px-6 py-4 sm:w-2/6">
                          <input
                            type="text"
                            style={{ minWidth: '200px' }}

                            value={edu.qualification || ''}
                            onChange={(e) => handleEducationChange(index, 'qualification', e.target.value)}
                            className="w-full p-2 border rounded-md"
                          />
                        </td>
                        <td className="px-6 py-4 sm:w-2/6">
                          <input
                            style={{ minWidth: '200px' }}

                            type="text"
                            value={edu.board || ''}
                            onChange={(e) => handleEducationChange(index, 'board', e.target.value)}
                            className="w-full p-2 border rounded-md"
                          />
                        </td>
                        <td className="px-6 py-4 sm:w-2/6">
                          <input
                            type="text"
                            style={{ minWidth: '200px' }}

                            value={edu.schoolName || ''}
                            onChange={(e) => handleEducationChange(index, 'schoolName', e.target.value)}
                            className="w-full p-2 border rounded-md"
                          />
                        </td>
                        <td className="px-6 py-4 sm:w-1/6">
                          <input
                            style={{ minWidth: '200px' }}

                            type="number"
                            id="year"
                            name="year"
                            min="1900"
                            max="2100"
                            value={edu.yearOfPassing || ''}
                            onChange={(e) => handleEducationChange(index, 'yearOfPassing', e.target.value)}
                            className="w-full p-2 border rounded-md"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-between mb-5">
              <button type="button" onClick={addEducationRow} className="text-white mt-3 py-2 px-4 transition-all duration-300 rounded bg-brandPrimary">
                Add Education
              </button>
              <button type="button" onClick={deleteEducationRow} className="text-white mt-3 py-2 px-4 transition-all duration-300 rounded bg-danger">
                Delete Education
              </button>
            </div>


            <div className='pt-3'>
              <input type="checkbox" value={checked} onChange={() => setChecked(!checked)} /> &nbsp;
              I understand and agree that the registation of my child/ward does not guarantee admission to the school.
            </div>

            <button type="submit" disabled={!checked} style={{ background: !checked ? 'skyblue' : 'darkblue' }} className=' text-white mt-3 py-2 px-4 transition-all duration-300 rounded'>{load ? 'Loading...' : 'REGISTER'} </button>

          </form>


        </div>
      </div>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-scroll';
import { FaXmark, FaBars } from 'react-icons/fa6'
import Logo from '../Assets/Logo.png'
import { IoIosArrowDown } from "react-icons/io";
import { Button, Modal } from 'flowbite-react';
import { useLocation, useNavigate } from 'react-router-dom';
import Offcanvas from './Offcanvas';
import AdminImg from '../Assets/admin_img.jpg'

const Navbar = ({ isAuth, setIsAuth }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openSubmenuId, setOpenSubmenuId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const location = useLocation();
    const [sticky, setSticky] = useState(false)
    const isLocate = location.pathname === '/registration'

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const Navigate = useNavigate()
    const [scrollDirection, setScrollDirection] = useState('none')
    const scrollPosition = useRef(0)
    const toggleMenu = () => {
        console.log("toggled");
        setIsMenuOpen(!isMenuOpen);
        Navigate('/')
    }

    const handleScroll = () => {
       
        const currentSrollY = window.scrollY;
        setScrollDirection(currentSrollY > scrollPosition.current ? 'down' : 'up')
        if (window.scrollY > 100) {
            setSticky(true);
        } else {
            setSticky(false);
        } 
        scrollPosition.current  = currentSrollY;

    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const navItems = [
        { link: "Home", path: "/" },
        { link: "About Us", path: "about-us" },
        { link: "Services", path: "services" },
        { link: "Gallery", path: "gallery" },
        {link: "Contact Us", path: 'contact'},
        {link: "Terms & Conditions", path: 'terms-&-condition'},
    ]


    const toggleSubmenu = (id) => {
        console.log("toggled");
        setOpenSubmenuId(openSubmenuId === id ? null : id);
    };

    const FormSubmit = () => {

        if (email === "admin@gmail.com" && password === "admin12345") {
            setIsAuth(true)
            Navigate('/admin-panel')
            setOpenModal(false)

        } else {
            alert("Invalid Crediential")
           
        }

    }

    const toggleandmodel1 = () => {
        if (isAuth) {
            Navigate('/admin-panel')
        } else {
            setOpenModal(true)
            setIsMenuOpen(false)
        }

    }
    const toggleandmodel2 = () => {
        Navigate('/application-form')
        setIsMenuOpen(false)
    }

    const linkRef = useRef(null);

const handleLinkClick = ()=>{
    Navigate('/')
   
}
    return (
        <>
            <Modal show={openModal} onClose={() => setOpenModal(false)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <div className='loginModel' ><Modal.Header>LOGIN</Modal.Header>  </div> 
               <div className="adminModelBody">
                 <Modal.Body>
                    <div className='grid lg:grid-cols-2 gap-2' >
                        <div className='hidden lg:flex '>
                            <img src={AdminImg} alt="" className='rounded-lg shadow' style={{height:'auto'}}/>
                        </div>
                   
                    <form  className="flex items-center justify-center flex-column lg:border lg:p-2">
                        <img src={Logo} width='70px' alt="" />
                        <div className="w-full mb-4 sm:mb-0">
                        <label
  htmlFor="name"
  className="block text-sm font-bold mb-2"
  style={{color:window.innerWidth<1028 ? 'white' : 'black'}}
>
  Email:
</label>
                            <input placeholder='E-mail Id' value={email} onChange={(e) => setEmail(e.target.value)} type="text" id="name" name="name" className="w-full px-3 py-2 border  rounded-md" />
                        </div>
                        <div className="w-full mb-4 sm:mb-0">
                            <label style={{color:window.innerWidth<1028 ? 'white' : 'black'}} for="email" className=" block text-sm font-bold mb-2">Password:</label>
                            <input placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} type="email" id="email" name="email" className="w-full px-3 py-2 border  rounded-md" />
                        </div>
                        <Button className="mt-3 w-full" onClick={FormSubmit}>Login</Button>

                    </form>
                    </div>
                </Modal.Body>
                </div>
               
            </Modal>



            <div className={`${window.innerWidth < 753 ? 'px-2' : 'px-5'}  bg-brandPrimary  py-1  flex justify-between align-center`} >
                <div className='my-2 flex align-center'>
                    <i style={{ fontSize: 'large' }} className="text-white m-0 p-0 fa-solid fa-envelope"></i>
                    <p style={{ fontWeight: '600', fontSize: window.innerWidth < 440 ? '12px' : '' }} className='m-0 px-2 text-white'>ahmcis@yahoo.co.in</p>
                </div>
                <div className='my-2 flex align-center '>
                    <i style={{ fontSize: 'large' }} className="text-white m-0 p-0 fa-solid fa-mobile-screen-button"></i>
                    <p style={{ fontWeight: '600', fontSize: window.innerWidth < 440 ? '12px' : '' }} className='m-0 px-2 text-white'>+91-9944930018</p>
                </div>
            </div>


            <header className={`w-full bg-white ${sticky  ? 'fixed top-0 left-0 right-0 HeadNav shadow' : ''}`} >
                <nav className={`py-2 lg:px-14 px-4 `}>
                    <div className='flex justify-between items-center text-base gap-5'>
                        <a href="/" className="flex items-center"><img src={Logo} className='rounded' width={window.innerWidth < 550 ? '50px' : '90px'} style={{height:window.innerWidth < 550 ? '50px' : '90px'}} />
                          <h1 style={{ fontSize: window.innerWidth < 550 ? '14px' : '25px' }} className="font-semibold px-3">ANNAI INDUSTRIAL SCHOOL</h1>
                        </a>

                        <div className='flex flex-column justify-between items-center text-base  gap-8 BGShade p-2 rounded'>
                        <div className='w-full flex  justify-end items-center'> 
                        <button onClick={toggleandmodel1} className='mx-3 hidden lg:flex bg-brandPrimary text-white py-2 px-4 transition-all duration-300 rounded hover:bg-neutralDGray'>LOGIN</button>
                             <button onClick={toggleandmodel2} className='hidden lg:flex bg-brandPrimary text-white py-2 px-4 transition-all duration-300 rounded hover:bg-neutralDGray'>APPLICATION FORM</button> 
                             </div>
                            
                            <ul className='lg:flex space-x-12 hidden '>
                                {!isLocate && navItems.map((item, id) => (
                                    
                                    <div key={item.path} className='relative group'>
                                    { item.link === "Terms & Conditions" ?
                                        <a href={item.path} style={{ cursor: 'pointer', display: 'inline-flex' }}
                                       
                                        smooth={true}
                                        offset={-135}
                                        duration={500}
                                        className='NAV block text-base text-gray900 hover:text-brandPrimary first:font-medium'>
                                        {item.link} 
                                    </a>  :
                                        <Link to={item.path}  style={{ cursor: 'pointer', display: 'inline-flex' }}
                                        onClick={handleLinkClick}
                                        smooth={true}
                                            offset={-135}
                                            duration={500}
                                            className='NAV block text-base text-gray900 hover:text-brandPrimary first:font-medium'>
                                            {item.link} {item.subItems && <IoIosArrowDown />}
                                        </Link>} 
                                        {!isLocate && item.subItems && (
                                            <div className='absolute bg-white border shadow-md p-2 space-y-2 hidden group-hover:block'>
                                                {item.subItems.map((subItem) => (
                                                    <Link style={{ cursor: 'pointer' }}
                                                        to={`${subItem.subPath}`}
                                                        smooth={true}
                                                        offset={-135}
                                                        duration={500}
                                                        key={subItem.subPath}
                                                        className='block text-base text-gray900 hover:text-brandPrimary first:font-medium'>
                                                        {subItem.label}
                                                    </Link>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </ul>


                           
                        </div>

                        {/*Menu for mobile screen */}
                        <div className='lg:hidden'>
                            <button onClick={toggleMenu} className="text-white bg-brandPrimary hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="button" data-drawer-target="drawer-navigation" data-drawer-show="drawer-navigation" aria-controls="drawer-navigation">
                                {
                                    isMenuOpen ? (<FaXmark className='h-5 w-5' />) : (<FaBars className='h-5 w-5' />)
                                }
                            </button>
                        </div>


                        <Offcanvas isOpen={isMenuOpen} onClose={toggleMenu}>
                        <div style={{ color:'black' }} className={`space-y-4 px-4 `} >
                                    {!isLocate && navItems.map((item, id) => (
                                        <div key={item.path}>
                                            { item.link === "Terms & Conditions" ?
                                        <a href={item.path} style={{ cursor: 'pointer', display: 'inline-flex' }}
                                        smooth={true}
                                        offset={-135}
                                        duration={500}
                                        className='NAV block text-base text-gray900 hover:text-brandPrimary first:font-medium'>
                                        {item.link} 
                                    </a>  :<Link
                                                onClick={!item.path ? () => toggleSubmenu(id) : toggleMenu}
                                                style={{ textAlign: 'left' }}
                                                smooth={true}
                                                offset={-135}
                                                duration={500}
                                                
                                                to={item.path && item.path}
                                                className='block w-full text-base  hover:text-white first:font-medium focus:outline-none'>
                                                {item.link}
                                            </Link>}
                                            {!isLocate && item.subItems && openSubmenuId === id && (
                                                <div className='pl-4 space-y-2'>
                                                    {item.subItems.map((subItem) => (
                                                        <Link
                                                            onClick={toggleMenu}
                                                            to={`${subItem.subPath}`}
                                                            smooth={true}
                                                            offset={-135}
                                                            duration={500}
                                                            key={subItem.subPath}
                                                            className='block text-base hover:text-white first:font-medium'>
                                                            {subItem.label}
                                                        </Link>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    <button onClick={toggleandmodel1} className=' bg-brandPrimary text-white py-2 px-4 transition-all duration-300 rounded hover:bg-neutralDGray'>LOGIN</button> <br />
                                                    <button onClick={toggleandmodel2} className=' bg-brandPrimary text-white py-2 px-4 transition-all duration-300 rounded hover:bg-neutralDGray'>REGISTER</button>
                             </div>
            </Offcanvas>

                        </div>
                </nav>
            </header>
        </>
    )
}

export default Navbar
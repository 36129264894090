import React from 'react'
import { Link } from 'react-router-dom'

const Error = ({Admin}) => {
  return (
    <div style={{display:'flex',flexDirection:'column' , alignItems:'center', justifyContent:'center', height:'100vh'}}>
        <h1 style={{fontSize:'50px', fontWeight:'800'}} className='text-center font-semibold'>{Admin ? '401 - Unauthorized':'404'}</h1>
         <p>{Admin ? 'Your authorization failed. Please Login again' : 'OOPS! PAGE NOT FOUND'}</p>
         <Link  to='/'>{Admin ? '' : 'Go to HomePage'}</Link>
    </div>
  )
}

export default Error

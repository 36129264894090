import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAkYhmHeHJuVghXX-sKsFTQyNccXOdIKqI",
  authDomain: "annai-industrial-school.firebaseapp.com",
  projectId: "annai-industrial-school",
  storageBucket: "annai-industrial-school.appspot.com",
  messagingSenderId: "886132490076",
  appId: "1:886132490076:web:3b2d5d7a897d40a4908b99",
  measurementId: "G-KQDXN20RJR"
};

export const firebaseApp = initializeApp(firebaseConfig);
 const database = getDatabase(firebaseApp);
 export default database
import React from 'react';
import { FaTimes } from 'react-icons/fa';

const Offcanvas = ({ isOpen, onClose, children }) => {
    return (
        <div  style={{zIndex:'111'}} className={`fixed top-0 right-0 bottom-0 w-64 bg-white border-l shadow-md transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
            <div className="p-4 flex justify-between" style={{alignItems: 'center'}}>
            <h1 className=' text-2xl'>MENU</h1>

                <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                    <FaTimes className="h-6 w-6" />
                </button>
            </div>
            <div className="p-4">{children}</div>
        </div>
    );
};

export default React.memo(Offcanvas);

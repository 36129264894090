import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../Assets/Logo.png';

import database from '../firebase';
import { get, onValue, ref, remove } from 'firebase/database';
import { Modal } from 'flowbite-react';
import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

export const Admin = ({ setIsAuth }) => {
  const Navigate = useNavigate();
  const [req, setReq] = useState(true);
  const [data, setData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteLoad, setDeleteLoad] = useState({});
  const [sortOrder, setSortOrder] = useState('desc');

  const [contact, setContact] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [openModal2, setOpenModal2] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactKeys, setContactKeys] = useState([]);

  const hanldeClick = () => {
    setIsAuth(false);
    Navigate('/');
  };

  useEffect(() => {
    const fetchData = () => {
      const dbRef = ref(database, 'Details');

      onValue(dbRef, (snapshot) => {
        try {
          if (snapshot.exists()) {
            setDataKeys(Object.keys(snapshot.val()));
            setData(Object.values(snapshot.val()));
          } else {
            setData([]);
          }
          setLoading(false);
          setError(null); // Clear error if it was previously set
        } catch (err) {
          setError(`Error: ${err.message}`);
          setLoading(false);
        }
      }, (error) => {
        setLoading(false);
        setError(`Error: ${error.message}`);
      });
    };

    fetchData();
  }, [req]);

    useEffect(() => {
    const fetchData = () => {
      const dbRef = ref(database, 'ContactDetails');

      onValue(dbRef, (snapshot) => {
        try {
          if (snapshot.exists()) {
           setContactKeys(Object.keys(snapshot.val())); 
           setContactData(Object.values(snapshot.val()));
          } else {
            setContactData([]);
          }
          setLoading(false);
          setError(null); // Clear error if it was previously set
        } catch (err) {
          setError(`Error: ${err.message}`);
          setLoading(false);
        }
      }, (error) => {
        setLoading(false);
        setError(`Error: ${error.message}`);
      });
    };

    fetchData();
  }, [contact]);

  const handleShowDetails = (originalIndex) => {
    setOpenModal(true);
    const selected = sortedData[originalIndex];
    setSelectedItem(selected);
    console.log(originalIndex);
  };

  const ShowContactDetails = (originalIndex) => {
    setOpenModal2(true);
    const selected = contactData[originalIndex];
    setSelectedContact(selected);
    console.log(originalIndex);
  };

  const handleDelete = async (originalIndex) => {
    try {
      setDeleteLoad((prev) => ({ ...prev, [originalIndex]: true }));
        const selectedItem = sortedData[originalIndex];

      const dataIndex = data.findIndex(
        (item) =>
          item.fName === selectedItem.fName &&
          item.lName === selectedItem.lName &&
          item.number === selectedItem.number
      );
  
      if (dataIndex !== -1) {
        const keyId = dataKeys[dataIndex];
        console.log(keyId);
        const dbRef = ref(database, `Details/${keyId}`);
        await remove(dbRef);
  
        setData(data.filter((_, index) => index !== dataIndex));
        console.log(dataIndex);
        console.log(data)
        setDataKeys(dataKeys.filter((_, index) => index !== dataIndex));
        console.log(dataKeys); 

      }
    } catch (error) {
      console.error('Error deleting item:', error.message);
    } finally {
      setDeleteLoad((prev) => ({ ...prev, [originalIndex]: false }));
    }
  };
  
const ContactDelete = async (originalIndex) =>{
  try {
    setDeleteLoad((prev) => ({ ...prev, [originalIndex]: true }));
      const selectedItem = contactKeys[originalIndex];
      const dbRef = ref(database, `ContactDetails/${selectedItem}`);
      await remove(dbRef);

      setContactData(contactData.filter((_, index) => index !== originalIndex));
      setContactKeys(contactKeys.filter((_, index) => index !== originalIndex));
 
  } catch (error) {
    console.error('Error deleting item:', error.message);
  } finally {
    setDeleteLoad((prev) => ({ ...prev, [originalIndex]: false }));
  }
}

  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortedData = data.slice().sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  });

  console.log(data)
  console.log(dataKeys); 
  console.log(contactData);
  console.log(contactKeys);

  const showReq = () =>{
    setReq(true)
    setContact(false)
  }

  const showContact = () =>{
    setReq(false)
    setContact(true)
  }
  console.log(selectedItem);
  return (
    <>
      <Modal show={openModal} onClose={() => setOpenModal(false)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Modal.Header className='CardHeader'>Details</Modal.Header>
        <Modal.Body >
          {selectedItem && (
            <> 
            <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
              <div>
                <p>Name: {selectedItem.name}</p>
                <p>Father Name: {selectedItem.fatherName}</p>
                <p>Date of Birth: {selectedItem.DOB}</p>
                <p>Email: {selectedItem.email}</p>
                <p>Community: {selectedItem.community}</p>
                <p>Nationality: {selectedItem.nationality}</p>
                <p>Address: {selectedItem.address}</p>
                <p>Razorpay Payment ID: {selectedItem.razorpay_payment_id}</p>

              </div>
              <div>
               <p>Application No: {selectedItem.appNumber}</p>
                <p>Father Occupation: {selectedItem.fatherOccupation}</p>
                <p>Age: {selectedItem.age}</p>
                <p>Gender: {selectedItem.gender}</p>
                <p>Subcaste: {selectedItem.subCaste}</p>
                <p>Contact Number: {selectedItem.number}</p>
                <p>Pincode: {selectedItem.pincode}</p>
                <p>Payment: {selectedItem.payment ? "Success" : "Failure"}</p>
              </div>
            </div>

            { selectedItem.educationDetails &&
            <div className="overflow-x-auto mt-3" style={{width: window.innerWidth<700 ? '300px' : '100%',overflowX:'auto'}}>
            <table className=" min-w-full bg-white border border-gray-300 ">
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b sm:w-1/6">S.No.</th>
                  <th className="px-6 py-3 border-b sm:w-2/6">Educational Qualification</th>
                  <th className="px-6 py-3 border-b sm:w-2/6">Board/University</th>
                  <th className="px-6 py-3 border-b sm:w-2/6">Name of School</th>
                  <th className="px-6 py-3 border-b sm:w-1/6">Year of Passing</th>
                </tr>
              </thead>
              <tbody>
              {
                (selectedItem.educationDetails).map((list, id)=>(
                  <tr key={id} className="bg-white hover:bg-gray-100">
                     <td className="px-6 py-4 sm:w-1/6">{id+1}</td>
                     <td className="px-6 py-4 sm:w-1/6">{list.qualification}</td>
                     <td className="px-6 py-4 sm:w-1/6">{list.board}</td>
                     <td className="px-6 py-4 sm:w-1/6">{list.schoolName}</td>
                     <td className="px-6 py-4 sm:w-1/6">{list.yearOfPassing}</td>
                    </tr>
                ))
              }
               </tbody>
               </table>
            </div>}
            
            </>

          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
       
      </Modal>

      <Modal show={openModal2} onClose={() => setOpenModal2(false)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Modal.Header className='CardHeader'>Details</Modal.Header>
      <Modal.Body >
        {selectedContact && (
          <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
            <div>
              <p>Name: {selectedContact.fName} {selectedContact.lName}</p>
              <p>E-mail: {selectedContact.email}</p>
              <p>Subject: {selectedContact.subject}</p>

            </div>
            <div>
              <p>Number: {selectedContact.number}</p>
              <p>Message: {selectedContact.message}</p>

            </div>
          </div>

        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>

      <div>
        <div className='bg-white shadow px-2 py-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <a href="/" className="flex items-center">
              <img src={Logo} className='rounded' width={window.innerWidth < 550 ? '50px' : '90px'} style={{ height: window.innerWidth < 550 ? '50px' : '90px' }} />
            </a>
          </div>
          <div>
            <button onClick={hanldeClick} className=' bg-brandPrimary text-white py-2 px-4 transition-all duration-300 rounded hover:bg-neutralDGray'>LOGOUT</button>
          </div>
        </div>

        <div className='flex flex-col w-full sm:flex-row'>
          <div className='w-full sm:w-1/4 lg:w-1/4 border lg:p-3 p-2'>
            <ul className='sm:block flex lg:block md:block' style={{ width: window.innerWidth < 640 ? 'fit-content' : '100%' }}>
              <li className={`${req ? 'bg-gray-100' : ''} p-2 m-2  rounded`} onClick={showReq}>Admission</li>
              <li className={`${contact ? 'bg-gray-100' : ''} p-2  m-2 rounded`} onClick={showContact}>Contact Us</li>
            </ul>
          </div>
          <div className='w-full sm:w-3/4 lg:w-3/4 bg-gray-100 border'>
            {loading && <div role="status" className='mt-2 flex items-center justify-center'>
              <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>}
            {error && <p>{error}</p>}
            {(!loading && !error && data.length > 0) && req  ? (
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="text-2xl p-3 border-b">Name</th>
                      <th className="text-2xl p-3 border-b">Date
                      <button onClick={handleSort} >
              {sortOrder === 'asc' ? '▲' : '▼'}
            </button>
                      </th>
                      <th className="text-2xl p-3 border-b">Payment</th>
                      <th className="text-2xl p-3 border-b">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((item, id) => (
                      <tr
                        key={id}
                        className="bg-white  p-2 my-3 rounded border-b"
                      >
                        <td className="p-3">
                          <strong className="text-1xl">{item.name}</strong>
                        </td>
                        <td className="p-3">
                          <strong>{new Date(item.date).toLocaleDateString()}</strong>
                        </td>
                        <td className="p-3 ">
                          <strong className='w-full text-center text-3xl'>{item.payment ? <span className='text-success'>&#10003; </span> : <span className='text-danger'> &#10006; </span>}</strong>
                        </td>
                        <td className="p-3">
                          <button
                            onClick={() => handleShowDetails(id)}
                            className="btn mx-2 btn-primary">
                            <FaEye />

                          </button>
                          <button
              onClick={() => handleDelete(id)}
              className="btn mx-2 btn-danger"
              disabled={deleteLoad[id]}
            >
              {!deleteLoad[id] ? (
                <MdDelete />
              ) : (
                <div role="status" className='mt-2 flex items-center justify-center'>
                  <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              )}
            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
            : req && !loading && !error ? (
              <p className='text-center mt-4'>No Data Found</p>
            ) : null}
           
           {
            (contact && !loading && !error && contactData.length > 0) ?
( 
            <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="text-2xl p-3 border-b">Name</th>
                  <th className="text-2xl p-3 border-b">Date
                </th>
                <th className="text-2xl p-3 border-b">Actions</th>

               </tr>
               
                
              </thead>
              <tbody>
                {contactData.map((item, id) => (
                  <tr
                    key={id}
                    className="bg-white  p-2 my-3 rounded border-b"
                  >
                    <td className="p-3">
                      <strong className="text-1xl">{item.fName} {item.lName}</strong>
                    </td>
                    <td className="p-3">
                      <strong>{new Date(item.date).toLocaleDateString()}</strong>
                    </td>
                   <td className="p-3">
                      <button
                        onClick={() =>ShowContactDetails(id)}
                        className="btn mx-2 btn-primary">
                        <FaEye />

                      </button>
                      <button
          onClick={() => ContactDelete(id)}
          className="btn mx-2 btn-danger"
          disabled={deleteLoad[id]}
        >
          {!deleteLoad[id] ? (
            <MdDelete />
          ) : (
            <div role="status" className='mt-2 flex items-center justify-center'>
              <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>) : contact && !loading && !error ? (
              <p className='text-center mt-4'>No Data Found</p>
            ) : null}

          </div>
        </div>
      </div>
    </>
  );
};

import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsYoutube } from 'react-icons/bs';
import Logo from '../Assets/Logo.png'
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaStar, FaStarHalf } from 'react-icons/fa';
import JD from '../Assets/jd_logo.png'

function Foot() {
  return (
    <Footer container>
      <div className="w-full">


        <div className="container-fluid ">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">

            <div className="col-span-1 md:col-span-2 lg:col-span-1">
              <img className="rounded-3" src={Logo} alt="" style={{ width: '100px', height: '100px' }} />
              <p className="mt-3 text-black text-base" style={{ fontSize: '16px', lineHeight: '28px', textAlign: 'justify' }}>
                We, Annai Industrial School at in Kanchipuram, Tamil Nadu, are focused on acquiring knowledge rather than imparting knowledge wherein teachers act as facilitators.
              </p>
            </div>

            <div className="col-span-1 md:col-span-2 lg:col-span-1">
              <h3 className="py-2 text-3xl" style={{ color: '#29166F', fontWeight: '700', fontFamily: 'roboto' }}>ANNAI INDUSTRIAL SCHOOL</h3>
              <div className="grid grid-cols-12 pt-3">
                <div className="col-span-1 custom-col-2"><i className="fa-solid fa-location-dot"></i></div>
                <div className="col-span-11 p-0">
                  <p className="text-black text-base" style={{ fontSsize: '16px', lineHeight: '1.3rem' }}>
                    No 111 A,Opposite to Narayanaguru School,<br/> Ekambaranathar Koil Sannathi Street,<br/>
                    Kanchipuram, Tamil Nadu 631502
                  </p>
                </div>
              </div>
              <hr className="my-2 border-white" />
              <div className="grid grid-cols-12">
                <div className="col-span-1 custom-col-2"><FiPhoneCall /></div>
                <div className="col-span-11 p-0">
                  <a href="tell:+91-9944930018 " style={{ textDecoration: 'none', color: 'unset' }}> <p style={{ display: 'inline-flex', alignItems: 'center' }}> +91-9944930018 </p> </a> <br />

                </div>
              </div>
              <hr className="my-2 border-white" />
              <div className="grid grid-cols-12">
                <div className="col-span-1 custom-col-2"><MdOutlineMailOutline /></div>
                <div className="col-span-11 p-0">
                  <a href="mailto:ahmcis@yahoo.co.in " style={{ textDecoration: 'none', color: 'unset' }}> <p style={{ display: 'inline-flex', alignItems: 'center' }}>  ahmcis@yahoo.co.in </p>   </a>

                </div>
              </div>
              <hr className="my-2 border-white" />
            </div>

            <div className="col-span-1 md:col-span-2 lg:col-span-1">
              <div className="mt-3 sm:d-flex sm:justify-center sm:flex-column">


                <div id="jdRating" className="jdRating jdRatingPaddingT10 jdRatingPaddingB20 ">
                  <div id="jdFooterRating" className="jdFooterRating" style={{ height: '36px', width: '200px', display: 'flex' }}>
                    <a href="https://www.justdial.com/Kanchipuram/Annai-Industrial-School-Kanchipuram-Railway-Station/9999PXX44-XX44-101013170328-Y5D2_BZDET" target="_blank"
                      style={{
                        height: '100%', width: '100%', display: 'flex', backgroundColor: 'rgb(255, 255, 255)',
                        borderRadius: '4px', border: '1px solid rgb(204, 204, 204)'
                      }}>
                      <div className="imgContainer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '20%' }}>
                        <img src={JD} className="p-1" alt="" />
                      </div>
                      <div className="ratingNumber" style={{ backgroundColor: 'rgb(0, 140, 65)', outline: 'rgb(0, 140, 65) solid 1px', color: 'white', display: 'flex', width: '20%', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ textAlign: 'center', width: '100%', fontSize: '15px', fontWeight: '600' }}>4.6</span>
                      </div>
                      <div className="star-ratings-css" style={{ color: 'rgb(197, 197, 197)', fontSize: '22px', height: '100%', margin: '0 auto', position: 'relative', padding: '0', display: 'inline-block' }}>
                        <div className="star-ratings-css-top" style={{ position: 'absolute', zIndex: '1', display: 'flex', alignItems: 'center', height: '100%', width: '92%', overflow: 'hidden' }}>
                          <FaStar style={{ color: 'rgb(233, 92, 41)' }} />
                          <FaStar style={{ color: 'rgb(233, 92, 41)' }} />
                          <FaStar style={{ color: 'rgb(233, 92, 41)' }} />
                          <FaStar style={{ color: 'rgb(233, 92, 41)' }} />
                          <FaStarHalf style={{ color: 'rgb(233, 92, 41)' }} />
                          {/* Repeat the above line for each star */}
                        </div>
                        <div className="star-ratings-css-bottom" style={{ display: 'flex', zIndex: '0', height: '100%', alignItems: 'center', overflow: 'hidden' }}>
                          <FaStar style={{ color: 'white' }} />
                          <FaStar style={{ color: 'white' }} />
                          <FaStar style={{ color: 'white' }} />
                          <FaStar style={{ color: 'white' }} />
                          <FaStarHalf style={{ color: 'white' }} />
                          {/* Repeat the above line for each empty star */}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="mt-4 flex space-x-6 sm:mt-0 pt-3">
                  <Footer.Icon href="http://www.facebook.com/AIAHSKANCHIPURAM" icon={BsFacebook} />
                  <Footer.Icon href="https://instagram.com/annai_kanchipuram?igshid=ZDdkNTZiNTM=" icon={BsInstagram} />
                  <Footer.Icon href="https://youtube.com/@annaiindustrialschool-kanc2549" icon={BsYoutube} />
                  <a href="http://www.justdial.com/dt-99JF6SBR">
                    <img className='w-5 h-5' src={JD} alt="JD" style={{ filter: 'grayscale(100%)', WebkitFilter: 'grayscale(100%)' }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-center">
          <Footer.Copyright href="/" by="Copyrights 2024 - 2025.Annai Industrial School.All Rights Reserved." />

        </div>
      </div>
    </Footer>
  );
}

export default Foot;